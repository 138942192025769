import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Meta from "../components/meta";
import Layout from "../components/layout";
import Section from "../components/section";

class RootIndex extends React.Component {
  render() {
    const siteMeta = get(this, "props.data.site.siteMetadata");
    const siteTitle = get(
      this,
      "props.data.site.contentfulPage.metaTitle",
      siteMeta.title
    );
    const siteDescription = get(
      this,
      "props.data.contentfulPage.metaDescription",
      siteMeta.description
    );
    const sections = get(this, "props.data.contentfulPage.sections", []);

    return (
      <Layout location={this.props.location}>
        <Meta
          defaultMeta={siteMeta}
          title={`${siteTitle}`}
          description={`${siteDescription}`}
        />
        {sections.map((section) => {
          return <Section key={section.id} {...section} />;
        })}
      </Layout>
    );
  }
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        image
      }
    }
    contentfulPage(slug: { eq: "home" }) {
      headline
      metaTitle
      metaDescription
      sections {
        id
        heading
        theme
        variant
        ctaLabel
        ctaLink {
          __typename
          ... on ContentfulPage {
            rootLink
            slug
          }
        }
        body {
          json
        }
        dividerAbove
        dividerBelow
        dividerStyle
      }
    }
  }
`;
